import React from "react";
import Layout from "../../components/Layout";
import { Card, CardColumns } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout>
        <div className="content">
          <section className="section section--gradient">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title is-size-3 has-text-weight-bold is-bold-light">Components</h1>
                    <p>
                      Components are a powerful way to keep 3Play Media's developers and designers in sync. Through the
                      design and development of smaller, reusable pieces of code, components help make the end user
                      experience more consistent. In addition, a robust design system with many well-documented
                      components helps developers create functioning applications and products more quickly and easily.
                    </p>
                    <h3>Overview</h3>
                    <p>
                      Most of 3Play Media's components are based off Twitter Bootstrap's atomic components. In
                      particular, we leverage the{" "}
                      <a href="https://react-bootstrap.github.io" rel="noopener noreferrer" target="_blank">
                        React Bootstrap library
                      </a>
                      , as our products and applications are built in React. Start by checking out the custom-styled
                      components we've documented in the left-hand sidebar. If you don't see a component that you need,
                      you can also search the{" "}
                      <a
                        href="https://react-bootstrap.github.io/components/alerts"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        React bootstrap documentation page
                      </a>{" "}
                      for existing components built on Bootstrap or <a href="/contact">request a new component</a> to be
                      added.
                    </p>
                    <h3>Components</h3>
                    <CardColumns>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="/img/development/buttons.png" />
                        <Card.Body>
                          <Card.Title>Buttons</Card.Title>
                          <a className="btn btn-primary" href="/development/button" variant="primary">Learn More</a>
                        </Card.Body>
                      </Card>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="/img/development/checkboxes.png" />
                        <Card.Body>
                          <Card.Title>Checkboxes</Card.Title>
                          <a className="btn btn-primary" href="/development/checkbox" variant="primary">Learn More</a>
                        </Card.Body>
                      </Card>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="/img/development/dropdowns.png" />
                        <Card.Body>
                          <Card.Title>Dropdowns</Card.Title>
                          <a className="btn btn-primary" href="/development/dropdown" variant="primary">Learn More</a>
                        </Card.Body>
                      </Card>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="/img/development/modals.png" />
                        <Card.Body>
                          <Card.Title>Modals</Card.Title>
                          <a className="btn btn-primary" href="/development/modal" variant="primary">Learn More</a>
                        </Card.Body>
                      </Card>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="/img/development/radio-buttons.png" />
                        <Card.Body>
                          <Card.Title>Radio Buttons</Card.Title>
                          <a className="btn btn-primary" href="/development/radio" variant="primary">Learn More</a>
                        </Card.Body>
                      </Card>
                      <Card style={{ width: "18rem" }}>
                        <Card.Img variant="top" src="/img/development/tooltips.png" />
                        <Card.Body>
                          <Card.Title>Tooltips</Card.Title>
                          <a className="btn btn-primary" href="/development/tooltip" variant="primary">Learn More</a>
                        </Card.Body>
                      </Card>
                    </CardColumns>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}
